<script lang="ts" setup>
const { t } = useI18n();

useSchemaOrg([
  defineWebPage({
    name: t('schema_title'),
    datePublished: new Date(2022, 4, 27, 20).toISOString(),
  }),
]);
</script>

<template>
  <main data-test-id="main-page">
    <SectionMainWelcome />
    <SectionMainProducts />
    <SectionMainCommentaries />
    <ClientOnly>
      <SectionMainInstallApp />
    </ClientOnly>
    <LayoutFooter />
  </main>
</template>

<i18n>
en:
  schema_title: Home

ru:
  schema_title: Главная
</i18n>
